import cloneDeep from 'clone-deep';
import type { ISbRichtext, SbRichTextOptions } from '@storyblok/js';

export default function renderCustomRichText(document: ISbRichtext | string) {
    const mySchema = cloneDeep(RichTextSchema);
    const { format: formatSbUrl } = useSbUrl();

    const resolver: SbRichTextOptions['resolver'] = (component, blok) => {
        switch (component) {
            case 'sb-content-image': {
                return `<img src="${blok.image.filename}" loading="lazy" class="sb-content-image">`;
            }
            case 'sb-content-button': {
                return blok.link.anchor ? `<a href="${formatSbUrl(blok.link.cached_url)}#${blok.link.anchor}"
                    class="rounded font-prenton-cond outline-none transition-colors duration-150 text-sm
                    leading-4 tracking-4xl p-xs bg-black text-white hover:bg-gray-800 focus:bg-gray-800
                    active:bg-gray-700 text-xs text-white md:text-base md:leading-5 p-4 no-underline"
                >
                ${blok.title}
                </a>` : `<a href="${formatSbUrl(blok.link.cached_url)}"
                    class="rounded font-prenton-cond outline-none transition-colors duration-150 text-sm
                    leading-4 tracking-4xl p-xs bg-black text-white hover:bg-gray-800 focus:bg-gray-800
                    active:bg-gray-700 text-xs text-white md:text-base md:leading-5 p-4 no-underline"
                >
                ${blok.title}
                </a>`;
            }
            case 'sb-freshworks-button': {
                return `<button type="button"
                    class="rounded font-prenton-cond outline-none transition-colors duration-150 text-sm
                    leading-4 tracking-4xl p-xs bg-black text-white hover:bg-gray-800 focus:bg-gray-800
                    active:bg-gray-700 text-xs text-white md:text-base md:leading-5 p-4 no-underline"
                onclick="FreshworksWidget('open');">
                ${blok.button_text}
            </button>`;
            }
            default: {
                return 'Resolver not defined';
            }
        }
    };

    return (
        typeof document === 'object'
            ? renderRichText(document, {
                schema: mySchema,
                resolver,
            })
            : document
    );
}
